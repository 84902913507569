import React from 'react'
import styled from 'styled-components'
import { wrangleIg13Data } from '../../utils/helpers'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import IG13Chart from './IG13Chart'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0;
`

const IG13ChartGrid = ({ regions, title }) => {
  const ig13Data = wrangleIg13Data(regions)
  const order = [
    'CHOLERA',
    'EBOLA',
    'MEASLES',
    'MENINGOCOCCUS',
    'POLIO_VAC',
    'POLIO_WILD',
    'YFEVER'
  ]
  return (
    <Container>
      {order.map((key, i) => (
        <IG13Chart
          key={key + i + i}
          data={ig13Data[key].sort((a, b) => (a.year > b.year ? 1 : -1))}
          pathogen={key}
          xScaleTo={2020 + ig13Data[key].length}
        />
      ))}
    </Container>
  )
}

export default IG13ChartGrid
