import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import Header from '../components/Header'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
import Notes from '../components/Notes'
import PageHeader from '../components/PageHeader'
import IG13ChartGrid from '../components/IG13/IG13ChartGrid'
import IG13Table from '../components/IG13/IG13Table'
import Footer from '../components/Footer'
import ImageExport from '../components/ImageExport'
import {
  LOCATION_LABELS,
  INCOME_GROUPS,
  UNI_REGIONS,
  WHO_LOCATION_OPTIONS,
  INC_LOCATION_OPTIONS,
  UNI_LOCATION_OPTIONS,
  REGION_LOOKUP
} from '../utils/const'
//import InsufficientData from '../components/InsufficientData'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'
import RegionInfo from '../components/RegionInfo'
import Disclaimer from '../components/Disclaimer'

const Ig13Page = ({ data, pageContext }) => {
  const { t } = useTranslation()

  const { sectionId } = pageContext
  const { content, footerContent } = data

  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')

  const isGbl = reg === 'GBL' || !reg
  const isInc = INCOME_GROUPS.includes(reg)
  const isUni = UNI_REGIONS.includes(reg)
  const regionLabel = isGbl ? t('global') : t(LOCATION_LABELS[reg])

  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      {/* <Header content={content} /> */}
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS}
          incOptions={INC_LOCATION_OPTIONS}
          uniOptions={UNI_LOCATION_OPTIONS}
        />
        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>
        <>
          <ImageExport
            title={`${content.chartTitle}—${regionLabel}`}
            dataSource={content.dataSourceNotesRt}
          >
            <IG13ChartGrid
              regions={data.regions.nodes.filter(
                (d) => d.CODE === REGION_LOOKUP[reg]
              )}
              // regions={
              //   isGbl
              //     ? data.global
              //     : isInc
              //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
              //     : isUni
              //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
              //     : data.regions.nodes.find((d) => d.iSO3 === reg)
              // }
              title={`${content.chartTitle}—${regionLabel}`}
            />
          </ImageExport>

          <IG13ChartGrid
            regions={data.regions.nodes.filter(
              (d) => d.CODE === REGION_LOOKUP[reg]
            )}
            // regions={
            //   isGbl
            //     ? data.global
            //     : isInc
            //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
            //     : isUni
            //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
            //     : data.regions.nodes.find((d) => d.iSO3 === reg)
            // }
            title={`${content.chartTitle}—${regionLabel}`}
          />
          <IG13Table
            regions={data.regions.nodes.filter(
              (d) => d.CODE === REGION_LOOKUP[reg]
            )}
            //   regions={
            //     isGbl
            //       ? data.global
            //       : isInc
            //       ? data.income.nodes.find((d) => d.incomeGroup === reg)
            //       : isUni
            //       ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
            //       : data.regions.nodes.find((d) => d.iSO3 === reg)
            //   }
          />
        </>
        {!isGbl && (isUni || isInc) && <Disclaimer />}
        {!isGbl && <RegionInfo reg={reg} />}
      </PageContent>
      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Ig13Page

// iso3 is null for global page but this works because it is the only null one
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleIg13CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }
    regions: allRegions2(filter: { IA2030_IND: { eq: "IG1.3" } }) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        YEAR_RANGE
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }
  }
`
// global: googleIg13Sheet(regionType: { eq: "GBL" }) {
//   id
//   iSO3
//   region
//   memberState
//   choleraBaseline
//   cholera2021
//   cholera2022
//   cholera2023
//   cholera2024
//   cholera2025
//   cholera2026
//   cholera2027
//   cholera2028
//   cholera2029
//   cholera2030

//   ebolaBaseline
//   ebola2021
//   ebola2022
//   ebola2023
//   ebola2024
//   ebola2025
//   ebola2026
//   ebola2027
//   ebola2028
//   ebola2029
//   ebola2030

//   measlesBaseline
//   measles2021
//   measles2022
//   measles2023
//   measles2024
//   measles2025
//   measles2026
//   measles2027
//   measles2028
//   measles2029
//   measles2030

//   meningBaseline
//   mening2021
//   mening2022
//   mening2023
//   mening2024
//   mening2025
//   mening2026
//   mening2027
//   mening2028
//   mening2029
//   mening2030

//   cVDPVBaseline
//   cVDPV2021
//   cVDPV2022
//   cVDPV2023
//   cVDPV2024
//   cVDPV2025
//   cVDPV2026
//   cVDPV2027
//   cVDPV2028
//   cVDPV2029
//   cVDPV2030

//   wPVBaseline
//   wPV2021
//   wPV2022
//   wPV2023
//   wPV2024
//   wPV2025
//   wPV2026
//   wPV2027
//   wPV2028
//   wPV2029
//   wPV2030

//   yellowFeverBaseline
//   yellowFever2021
//   yellowFever2022
//   yellowFever2023
//   yellowFever2024
//   yellowFever2025
//   yellowFever2026
//   yellowFever2027
//   yellowFever2028
//   yellowFever2029
//   yellowFever2030
// }
// regions: allGoogleIg13Sheet(filter: { regionType: { eq: "REG" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     choleraBaseline
//     cholera2021
//     cholera2022
//     cholera2023
//     cholera2024
//     cholera2025
//     cholera2026
//     cholera2027
//     cholera2028
//     cholera2029
//     cholera2030

//     ebolaBaseline
//     ebola2021
//     ebola2022
//     ebola2023
//     ebola2024
//     ebola2025
//     ebola2026
//     ebola2027
//     ebola2028
//     ebola2029
//     ebola2030

//     measlesBaseline
//     measles2021
//     measles2022
//     measles2023
//     measles2024
//     measles2025
//     measles2026
//     measles2027
//     measles2028
//     measles2029
//     measles2030

//     meningBaseline
//     mening2021
//     mening2022
//     mening2023
//     mening2024
//     mening2025
//     mening2026
//     mening2027
//     mening2028
//     mening2029
//     mening2030

//     cVDPVBaseline
//     cVDPV2021
//     cVDPV2022
//     cVDPV2023
//     cVDPV2024
//     cVDPV2025
//     cVDPV2026
//     cVDPV2027
//     cVDPV2028
//     cVDPV2029
//     cVDPV2030

//     wPVBaseline
//     wPV2021
//     wPV2022
//     wPV2023
//     wPV2024
//     wPV2025
//     wPV2026
//     wPV2027
//     wPV2028
//     wPV2029
//     wPV2030

//     yellowFeverBaseline
//     yellowFever2021
//     yellowFever2022
//     yellowFever2023
//     yellowFever2024
//     yellowFever2025
//     yellowFever2026
//     yellowFever2027
//     yellowFever2028
//     yellowFever2029
//     yellowFever2030
//   }
// }
// income: allGoogleIg13Sheet(filter: { regionType: { eq: "INC" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     incomeGroup
//     choleraBaseline
//     cholera2021
//     cholera2022
//     cholera2023
//     cholera2024
//     cholera2025
//     cholera2026
//     cholera2027
//     cholera2028
//     cholera2029
//     cholera2030

//     ebolaBaseline
//     ebola2021
//     ebola2022
//     ebola2023
//     ebola2024
//     ebola2025
//     ebola2026
//     ebola2027
//     ebola2028
//     ebola2029
//     ebola2030

//     measlesBaseline
//     measles2021
//     measles2022
//     measles2023
//     measles2024
//     measles2025
//     measles2026
//     measles2027
//     measles2028
//     measles2029
//     measles2030

//     meningBaseline
//     mening2021
//     mening2022
//     mening2023
//     mening2024
//     mening2025
//     mening2026
//     mening2027
//     mening2028
//     mening2029
//     mening2030

//     cVDPVBaseline
//     cVDPV2021
//     cVDPV2022
//     cVDPV2023
//     cVDPV2024
//     cVDPV2025
//     cVDPV2026
//     cVDPV2027
//     cVDPV2028
//     cVDPV2029
//     cVDPV2030

//     wPVBaseline
//     wPV2021
//     wPV2022
//     wPV2023
//     wPV2024
//     wPV2025
//     wPV2026
//     wPV2027
//     wPV2028
//     wPV2029
//     wPV2030

//     yellowFeverBaseline
//     yellowFever2021
//     yellowFever2022
//     yellowFever2023
//     yellowFever2024
//     yellowFever2025
//     yellowFever2026
//     yellowFever2027
//     yellowFever2028
//     yellowFever2029
//     yellowFever2030
//   }
// }
// unicef: allGoogleIg13Sheet(filter: { regionType: { eq: "UNI" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     uNICEFRegion
//     choleraBaseline
//     cholera2021
//     cholera2022
//     cholera2023
//     cholera2024
//     cholera2025
//     cholera2026
//     cholera2027
//     cholera2028
//     cholera2029
//     cholera2030

//     ebolaBaseline
//     ebola2021
//     ebola2022
//     ebola2023
//     ebola2024
//     ebola2025
//     ebola2026
//     ebola2027
//     ebola2028
//     ebola2029
//     ebola2030

//     measlesBaseline
//     measles2021
//     measles2022
//     measles2023
//     measles2024
//     measles2025
//     measles2026
//     measles2027
//     measles2028
//     measles2029
//     measles2030

//     meningBaseline
//     mening2021
//     mening2022
//     mening2023
//     mening2024
//     mening2025
//     mening2026
//     mening2027
//     mening2028
//     mening2029
//     mening2030

//     cVDPVBaseline
//     cVDPV2021
//     cVDPV2022
//     cVDPV2023
//     cVDPV2024
//     cVDPV2025
//     cVDPV2026
//     cVDPV2027
//     cVDPV2028
//     cVDPV2029
//     cVDPV2030

//     wPVBaseline
//     wPV2021
//     wPV2022
//     wPV2023
//     wPV2024
//     wPV2025
//     wPV2026
//     wPV2027
//     wPV2028
//     wPV2029
//     wPV2030

//     yellowFeverBaseline
//     yellowFever2021
//     yellowFever2022
//     yellowFever2023
//     yellowFever2024
//     yellowFever2025
//     yellowFever2026
//     yellowFever2027
//     yellowFever2028
//     yellowFever2029
//     yellowFever2030
//   }
// }
