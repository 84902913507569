import React, { useState } from 'react'
import styled from 'styled-components'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { scaleLinear, scaleBand } from 'd3-scale'
import { PATHOGEN_LABELS_API } from '../../utils/const'
import { useDimensionsContext } from '../ChartContext'
import { useChartDimensions } from '../../hooks/dimensions'
import ChartProvider from '../ChartContext'
import { range } from 'd3-array'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { findTrendLine } from '../../utils/helpers'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useTranslation } from 'react-i18next'

const StyledXAxis = styled.g`
  font-size: 1.1rem;
  fill: var(--darkGray);
  text-anchor: middle;
  }
`
const XAxis = ({ x, xScaleTo }) => {
  const { t } = useTranslation()
  const xTicks = range(2020, xScaleTo)
  const dimensions = useDimensionsContext()
  return (
    <StyledXAxis
      className="axis"
      transform={`translate(0, ${dimensions.boundedHeight})`}
    >
      {xTicks.map((tick, i) => {
        return (
          <g
            key={tick}
            style={{
              transform: `translate(${x(tick) + x.bandwidth() / 2}px, 0)`
            }}
          >
            <text
              y={25}
              dy="0.71em"
              fontWeight={tick === 2020 ? 'bold' : 'normal'}
            >
              {tick === 2020 ? t('Base') : tick}
            </text>
            <text
              y={47}
              dy="0.71em"
              fontWeight={tick === 2020 ? 'bold' : 'normal'}
            >
              {tick === 2020 ? t('line') : ''}
            </text>
          </g>
        )
      })}
    </StyledXAxis>
  )
}

const StyledYAxis = styled.g`
  font-size: 1rem;
  fill: var(--darkGray);
  dominant-baseline: middle;
  text-anchor: end;
  .solid-line {
    stroke: var(--lightGray);
  }
`

const YAxis = ({ y, yScaleTo }) => {
  const dimensions = useDimensionsContext()
  const yTicks = y.ticks(5)

  return (
    <StyledYAxis>
      {yTicks.map((tick, i) => {
        return (
          <g
            key={tick}
            style={{
              transform: `translate(0, ${y(tick)}px)`
            }}
          >
            <line className="solid-line" x2={dimensions.boundedWidth} />
            <text x={-10}>{tick}</text>
          </g>
        )
      })}
    </StyledYAxis>
  )
}

//TODO: change max-width with mqs -- but make it a calculated value so it can handle more years of data
const Figure = styled.figure`
  flex: 1 1;
  min-width: 400px;
  max-width: 400px;
  height: 65vh;
  min-height: 65vh;
  max-height: 70vh;
  ${Mq.md} {
    min-width: 100%;
    max-width: 100%;
  }
  // ${Mq.xs} {
  //   max-width: 100%;
  // }
`

const PathogenTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ color }) => color};
`
const DeltaIndicator = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  height: 2.75rem;
  color: ${({ textColor }) => textColor};
  font-size: 0.85rem;
  font-weight: bold;
  line-height: 1.2;
  border-radius: 50px;
  background-color: ${({ color }) => color};
  margin: 1rem 0;
  padding-right: 1rem;
  ${Mq.xs} {
    font-size: 0.8rem;
  }
`
const IconBox = styled.div`
  margin: 0.25rem 0.5rem 0 0.5rem;
`
const StyledRect = styled.rect`
  fill: ${({ direction }) =>
    direction === 'stable' || direction === null
      ? `var(--lightGray)`
      : direction === 'increasing'
      ? `var(--paleOrange)`
      : `var(--paleBlue)`};

  &:hover {
    fill: ${({ direction }) =>
      direction === 'stable' || direction === null
        ? `var(--gray)`
        : direction === 'increasing'
        ? `var(--darkOrange)`
        : `var(--brightBlue)`};
    opacity: 0.75;
  }
`
const IG13Chart = ({ data, pathogen, xScaleTo }) => {
  const { t } = useTranslation()
  const [hovered, setHovered] = useState()
  const [ref, dimensions] = useChartDimensions({
    marginTop: 32,
    marginRight: 32,
    marginBottom: 200,
    marginLeft: 36
  })
  // const dummyData = [
  //   { year: 2020, value: 31 },
  //   { year: 2021, value: 54 },
  //   { year: 2022, value: 24 },
  //   { year: 2023, value: 14 }
  // ]
  //find largest value to determine which scale to use, 10 or 100
  const highestValue = Math.max(
    ...data.map((d) => {
      return d.VALUE_NUM
    })
  ) //dummyData.sort((a, b) => b.value - a.value)[0].value
  const yScaleTo = highestValue <= 10 ? 10 : 100
  const x = scaleBand(range(2020, xScaleTo), [
    0,
    dimensions.boundedWidth
  ]).padding(0.2)
  const y = scaleLinear([0, yScaleTo], [dimensions.boundedHeight, 0])

  //if data has any negative values, i.e. missing data, take out these values in order to find trendline
  const preppedData = data.filter((d) => d.VALUE_NUM >= 0)
  const trendData = findTrendLine(preppedData)

  const direction =
    preppedData.length >= 2
      ? trendData[1] < trendData[3]
        ? 'increasing'
        : trendData[1] > trendData[3]
        ? 'decreasing'
        : 'stable'
      : null

  const handleMouseEnter = (i) => {
    setHovered(i)
  }

  const handleMouseLeave = () => {
    setHovered()
  }

  return (
    <Figure
      ref={ref}
      role="img"
      alt={`${t(
        'Bar chart of number of large or disruptive VPD outbreaks by year of'
      )} ${pathogen}.`}
    >
      <PathogenTitle
        //color="var(--brightBlue)"
        color={
          direction === 'stable' || direction === null
            ? `var(--gray)`
            : direction === 'increasing'
            ? `var(--darkOrange)`
            : `var(--brightBlue)`
        }
      >
        {t(PATHOGEN_LABELS_API[pathogen])}
      </PathogenTitle>
      <DeltaIndicator
        color={
          direction === 'stable'
            ? `var(--lightGray)`
            : direction === 'increasing'
            ? `var(--paleOrange)`
            : direction === 'decreasing'
            ? `var(--paleBlue)`
            : `var(--white)`
        }
        textColor={
          direction === 'stable'
            ? `var(--darkGray)`
            : direction === 'increasing'
            ? `var(--darkOrange)`
            : `var(--brightBlue)`
        }
      >
        <IconBox>
          {direction === 'stable' || direction === null ? (
            <IoCheckmarkCircle color={'transparent'} size={42} />
          ) : direction === 'increasing' ? (
            <BsFillExclamationDiamondFill color={'#d96038'} size={42} />
          ) : (
            <IoCheckmarkCircle color={'#4681de'} size={44} />
          )}
        </IconBox>
        <div>
          {direction
            ? `${t('number of large or disruptive outbreaks')} ${t(direction)}`
            : ''}
        </div>
      </DeltaIndicator>
      <ChartProvider dimensions={dimensions}>
        <g>
          <XAxis x={x} xScaleTo={xScaleTo} />
          <YAxis y={y} yScaleTo={yScaleTo} />
          {data?.map((d, i) => (
            <React.Fragment key={pathogen + i}>
              <StyledRect
                direction={direction}
                x={x(d.year)}
                y={y(d.VALUE_NUM >= 0 ? d.VALUE_NUM : 0)}
                width={x.bandwidth()}
                height={y(0) - y(d.VALUE_NUM)}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={() => handleMouseLeave()}
              />
              <text
                x={x(d.year) + x.bandwidth() / 2}
                y={y(d.VALUE_NUM) + 20}
                fill={hovered === i ? `var(--white)` : 'none'}
                textAnchor="middle"
                fontSize="1rem"
                fontWeight="bold"
              >
                {d.VALUE_NUM}
              </text>
            </React.Fragment>
          ))}
          {/* trendline */}
          {direction && (
            <>
              <defs>
                <marker
                  id="orangeArrowhead"
                  markerWidth="4"
                  markerHeight="3"
                  refX="0"
                  refY="1.5"
                  orient="auto"
                  fill={`var(--darkOrange)`}
                >
                  <polygon points="0 0, 4 1.5, 0 3" />
                </marker>
                <marker
                  id="blueArrowhead"
                  markerWidth="4"
                  markerHeight="3"
                  refX="0"
                  refY="1.5"
                  orient="auto"
                  fill={`var(--brightBlue)`}
                >
                  <polygon points="0 0, 4 1.5, 0 3" />
                </marker>
                <marker
                  id="grayArrowhead"
                  markerWidth="4"
                  markerHeight="3"
                  refX="0"
                  refY="1.5"
                  orient="auto"
                  fill={`var(--gray)`}
                >
                  <polygon points="0 0, 4 1.5, 0 3" />
                </marker>
              </defs>
              <line
                x1={x(trendData[0]) + x.bandwidth() / 2}
                y1={y(trendData[1])}
                x2={x(trendData[2]) + x.bandwidth() / 2}
                y2={y(trendData[3])}
                stroke={
                  direction === 'stable'
                    ? `var(--gray)`
                    : direction === 'increasing'
                    ? `var(--darkOrange)`
                    : `var(--brightBlue)`
                }
                strokeWidth="4"
                strokeLinecap="round"
                markerEnd={
                  direction === 'stable'
                    ? 'url(#grayArrowhead)'
                    : direction === 'increasing'
                    ? 'url(#orangeArrowhead)'
                    : 'url(#blueArrowhead)'
                }
              />
            </>
          )}
        </g>
      </ChartProvider>
    </Figure>
  )
}

export default IG13Chart
